import React from "react"
import QueueAnim from "rc-queue-anim"
import { Button, Col, Row, Typography } from "antd"
import BannerImage from "./BannerImage"

const { Text } = Typography

const Banner = () => (
  <div className={`home-layout-wrapper home-banner`}>
    <div className="home-layout">
      <Row type="flex" justify="center" align="middle">
        <Col xs={24} md={9}>
          <QueueAnim
            className={`home-banner-content-wrapper`}
            delay={300}
            ease="easeOutQuart"
          >
            <h1 key="h2">
              Software is our{" "}
              <Text type="danger" underline>
                Passion
              </Text>
            </h1>
            <p key="p">Get your startup idea into the real world</p>
            <span key="button">
              <Button type="danger">
                <a href="mailto:rodrigo@codepolaris.com">Hire Us</a>
              </Button>
            </span>
          </QueueAnim>
        </Col>
        <Col xs={24} md={9}>
          <div className={`home-banner-image-wrapper`}>
            <BannerImage />
          </div>
        </Col>
      </Row>
    </div>
  </div>
)

export default Banner
