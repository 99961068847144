import React from "react"
import { Row, Col } from "antd"

import mobits from "../images/mobits.png"
import dashbid from "../images/dashbid.png"
import bairesdev from "../images/bairesdev.jpg"
import chime from "../images/chime.svg"
import jetsemani from "../images/jetsemani.png"
import juryvox from "../images/juryvox.svg"

export default function Customers() {
  const page4 = [mobits, dashbid, bairesdev, chime, jetsemani, juryvox]

  const children = page4.map((d, i) => (
    <Col className="col" xs={12} md={8} key={i}>
      <i style={{ backgroundImage: `url(${d})` }} />
    </Col>
  ))
  return (
    <div className="home-layout-wrapper home-user-wrapper">
      <div className="home-layout">
        <div className="home-user">
          <h2 key="h2">Brands that Love us</h2>
          <i key="i" className="line" />
          <Row type="flex" justify="center" align="middle" gutter={40}>
            {children}
          </Row>
        </div>
      </div>
    </div>
  )
}
