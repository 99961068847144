import React from "react"
import QueueAnim from "rc-queue-anim"
import { Row, Col } from "antd"

import services1 from "../images/services1.png"
import services2 from "../images/services2.png"
import services3 from "../images/services3.png"

export default function Services() {
  const page1 = [
    {
      title: "Full Stack Development",
      content:
        "We have a flexible technology approach with various skillsets and abilities that will bring life to your product.",
      src: services1,
    },
    {
      title: "Agile Methodology",
      content:
        "Our development process prioritizes collaboration with the client and the delivery of software in operation every week.",
      src: services3,
    },
    {
      title: "Product Strategy",
      content:
        "With an experienced team dedicated to your idea at no additional cost, we help you achieve your goals.",
      src: services2,
    },
  ]
  const children = page1.map((d, i) => (
    <QueueAnim
      component={Col}
      key={i}
      type="bottom"
      className="col"
      componentProps={{ md: 6, xs: 24 }}
    >
      <div
        key="image"
        className="image"
        style={{ backgroundImage: `url(${d.src})` }}
      />
      <h3 key="h3">{d.title}</h3>
      <p key="p">{d.content}</p>
    </QueueAnim>
  ))
  return (
    <div className="home-layout-wrapper home-func-wrapper" id="home-func">
      <h2>Our Services</h2>
      <i className="line" />
      <div className="home-layout">
        <QueueAnim
          className="home-func"
          type="bottom"
          key="home-func"
          ease="easeOutQuart"
          leaveReverse
        >
          <QueueAnim
            key="content"
            component={Row}
            type="bottom"
            componentProps={{
              gutter: 100,
              type: "flex",
              justify: "center",
              align: "middle",
            }}
          >
            {children}
          </QueueAnim>
        </QueueAnim>
      </div>
    </div>
  )
}
