import React from "react"
import DocumentTitle from "react-document-title"
import { Layout, Menu, Icon } from "antd"

import Banner from "../components/Banner"
import Services from "../components/Services"
import Customers from "../components/Customers"

import "antd/dist/antd.css"
import "../styles/index.less"

const { Header, Content, Footer } = Layout

export default () => (
  <Layout className="layout">
    <Header style={{ display: "flex", alignItems: "center" }}>
      <Menu style={{ textAlign: "center" }} theme="dark" mode="horizontal">
        <Menu.Item key="1">
          <a href="mailto:rodrigo@codepolaris.com">Contact</a>
        </Menu.Item>
      </Menu>
    </Header>
    <Content className="home-page">
      <Banner />
      <Services />
      <Customers />
    </Content>
    <Footer style={{ textAlign: "center" }}>
      Code Polaris © {new Date().getFullYear()} Made with{" "}
      <Icon type="heart" theme="filled" /> in Rio
    </Footer>
    <DocumentTitle title="Code Polaris | Software for Startups" />
  </Layout>
)
